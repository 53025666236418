$(document).ready(function() {

  //----------------------------------------------------------------------------
  //  FOUNDATION
  //----------------------------------------------------------------------------

  $(document).foundation({
    equalizer : {
     // Specify if Equalizer should make elements equal height once they become stacked.
     equalize_on_stack: true
    }
  });

  var isLarge, isMedium, isSmall;

  isSmall = function() {
    return matchMedia(Foundation.media_queries['small']).matches && !matchMedia(Foundation.media_queries.medium).matches;
  };

  isMedium = function() {
    return matchMedia(Foundation.media_queries['medium']).matches && !matchMedia(Foundation.media_queries.large).matches;
  };

  isLarge = function() {
    return matchMedia(Foundation.media_queries['large']).matches;
  };

  //----------------------------------------------------------------------------
  //  MOBILE NAV MENU
  //----------------------------------------------------------------------------

    var mobileNavMenuIcon = $('#mobile-nav-toggle');
    var mobileNav = $('#nav');

    mobileNavMenuIcon.on('click', function(e) {
      e.preventDefault();
      mobileNav.slideToggle();

    });


  //----------------------------------------------------------------------------
  //  FAQ QUESTION ANIMATION
  //----------------------------------------------------------------------------

  $('.question').on('click', function(event) {
    event.preventDefault();
    $(this).siblings('.answer').slideToggle();
    // Rotate arrow
    $hasClass = $(this).find('h3').hasClass('show');
    if ($hasClass == false) {
      $(this).find('h3').addClass('show');
    } else {
      $(this).find('h3').removeClass('show');
    }
  });

  //----------------------------------------------------------------------------
  //  INLINE JS ROUNDUP TODO: Better format this into sections
  //----------------------------------------------------------------------------

  var $disclosure = $('#disclosure-tooltip');
  $('#advertisers-disclosure').click(
    function (e)
    {
      e.preventDefault();
      $disclosure.show();
    }
  );
  $('#close-tooltip').click(
    function ()
    {
      $disclosure.hide();
    }
  );

  $overTen = $('.over-ten');
  $('#show-all').click(
    function ()
    {
      $overTen.removeClass('over-ten');
      $(this).hide();
    }
  );

  $('#paginationDropdown').on('change', function(){
    var pageIndex = $(this).val();

    if (pageIndex == 1)
    {
      window.location = '/vpn-comparison';
    }
    else
    {
      window.location = '/vpn-comparison/' + pageIndex;
    }
  });

  //----------------------------------------------------------------------------
  //
  //----------------------------------------------------------------------------

  $("#user-review-open").on(
    "click", function (e)
    {
      e.preventDefault();
      $("#formReview").stop().slideToggle(800);
    }
  );
});



